<template>
    <ul class="tab-bar">
       <li v-for="(item,index) in boss.renderBarList"
            :key="index"
            :class="currentPageLink == item.link ? 'current' : '' "
            @click="routerLinkTo(item.link)">
            <img :src="currentPageLink == item.link ? item.imageUrl : item.uncheckImgUrl" alt="">
            <p>{{item.name}}</p>
        </li>
    </ul>
</template>
<script>

	import util from '@/utils/util'
  import { mapState } from 'vuex';
	export default {
		name:"tabBar",
		data(){
			return {
				currentPageLink:'revenue'
			}
		},
		computed:{...mapState(['boss','userStatus'])},
		watch:{
			$route(to,from){
				this.currentPageLink = to.name
			}
		},
		methods:{
			/**
			 * 页面跳转
			 */
			routerLinkTo(link){
				this.$router.push({
					name:link
				})
			},
		},
		created(){
      this.currentPageLink = this.$route.name
    }
	}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "~@/assets/style/public.scss";
    /*底部导航*/
    .tab-bar{
        height:100px;
        background:#FFFFFF;
        left:0;
        right:0;
        border-top:1px solid $border-color;
        @extend %flex-center-row;

        li{
            flex:1;
            display: flex;
            flex-direction: column;
            align-items: center;
            // text-align:center;
            // cursor:pointer;
            // display:inline-block;
        }

        img{
            width:40px;
            height:38px;
            margin:15px 0 8px;
        }

        p{
            @include font-vertical-middle(20px);
        }

        .current{
            color:$high-light-color;
        }
    }
</style>
